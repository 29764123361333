import type { ShowcasePopulationLevel } from 'root/apiTypes';

export const MAX_NUMBER_OF_ENTITIES_FROM_SERVER = 500;

export const SHOWCASE_POPULATION_LEVEL: ShowcasePopulationLevel = {
  labels: true,
  modifierGroups: false,
  modifiers: false,
  priceVariants: true,
};

export const WIDGET_IDS = {
  menus: 'ac6175c0-249c-42f3-81e5-c8facc4bd0bf',
};

export const MIN_COLUMNS_NUMBER = 1;
export const MAX_COLUMNS_NUMBER = 5;
export const BADGE_ICON_SIZE = 16;

export const APP_DEF_ID = 'a63b2da7-5c57-4beb-9341-51ce59c50972';
