import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { PopulatedMenuClient } from 'root/api/PopulatedMenuClient';
import { getMonitoredApiCall } from 'root/api/utils/getMonitoredApiCall';
import type { GetAllResponse } from 'root/api/utils/types';
import type { PopulatedMenu } from 'root/apiTypes';
import { getPriceFormatter } from 'root/utils/currency';
import { goToPage } from 'root/utils/navigation';
import { WarmupDataManager } from 'root/utils/WarmupDataManager';

let menusPromise: Promise<{ data: GetAllResponse<PopulatedMenu> | undefined; error: Error | undefined }> | undefined;

const createController: CreateControllerFn = ({ flowAPI }) => {
  const priceFormatter = getPriceFormatter(flowAPI);
  const navigateTo = goToPage(flowAPI);

  flowAPI.controllerConfig.setProps({ priceFormatter });
  flowAPI.controllerConfig.setProps({ navigateTo });

  const warmupData = new WarmupDataManager(
    flowAPI.controllerConfig.wixCodeApi.window.warmupData,
    flowAPI.environment.isSSR
  );

  const getMonitoredPopulatedMenuClient = () =>
    getMonitoredApiCall(() => PopulatedMenuClient(flowAPI.essentials.httpClient).getAll({}), flowAPI.reportError);

  const setMenus = (menus: PopulatedMenu[]) => {
    flowAPI.controllerConfig.setProps({ menus });
  };

  if (!menusPromise) {
    menusPromise = warmupData.manageData<{
      data: GetAllResponse<PopulatedMenu> | undefined;
      error: Error | undefined;
    }>(getMonitoredPopulatedMenuClient, 'populatedMenus');
  }
  return {
    async pageReady() {
      if (menusPromise) {
        const { data: menus } = (await menusPromise) || {};
        setMenus(menus?.data ?? []);
      }
    },
  };
};

// eslint-disable-next-line import/no-default-export
export default createController;
